import { useEffect, useState, useMemo, Fragment, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import _ from 'lodash'
import fp from 'lodash/fp'

import VisuallyHidden from '@reach/visually-hidden'

import * as colors from '../config/theme/colors'
import media, { useBreakpoint } from '../config/theme/media'

import Link from './Link'
import Icon from './Icon'
import Button from './Button'
import H3 from './Typography/H3'
import Span from './Typography/Span'
import useTranslate from '../hooks/useTranslate'

import { Block } from 'components/Blocks'
import Spinner from "components/Spinner"
import Autosuggest from 'components/AutoSuggest'

const autocompleteStyle = {
  container: {
    flexGrow: 2
  },
  suggestionsContainer: {
    display: 'none',
    position: 'absolute',
    zIndex: 1
  },
  suggestionsContainerOpen: {
    display: 'block'
  },
  suggestionsList: {
    listStyleType: 'none',
    margin: 0,
    padding: '10px 0px'
  },
  suggestionHighlighted: {
    background: colors.grey()
  }
}

const GlobalStyle = createGlobalStyle`
  body, html {
    overflow: ${props => props.showSearch ? 'hidden' : 'inherit'};
    position: ${props => props.showSearch ? 'fixed' : 'inherit'};
  }
`

const Container = styled.div`
  z-index: 2;
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  justify-content: center;
`
const MobileContainer = styled.div`
  display: none;
  width: 100%;
  position: fixed;
  inset: 0;
  top: ${props => props.showSearch ? '0' : '100%'};
  height: 100%;
  background: ${colors.white()};
  transition: all .2s ease;
  z-index: 1502;
  ${media.lessThan('md')`
    display: block;
  `}
`

const Submit = styled(Button)`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`
const StyledAutocomplete = styled(Autosuggest)`
  flex-grow: 2;
  position: relative;
`

const Input = styled.input`
  height: 60px;
  line-height: 60px;
  padding: 0 0 0 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${colors.searchBarBorderColor};
  ${media.forEach({
  values: { sm: '16', md: '16', lg: '18' },
  getStyle: (val) => `font-size: ${val}px;`
})}

  ${media.lessThan('md')`
    height: 40px;
    line-height: 40px;
  `}
`

const List = styled.div`
  background: ${colors.white()};
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${colors.searchBarBorderColor};
  overflow: auto;
  max-height: 300px;
  overflow-y: scroll;
  ${media.lessThan('md')`
    inset: 0;
    top: ${props => props.offset}px;
    max-height: none;
  `}
  ${({ hasFallbackItems }) => hasFallbackItems && `
     > div:last-child {
      background: ${colors.grey()};
      padding: 20px 0 20px 15px;
      ${media.greaterThan('md')`
        padding-left: 30px;
      `}
    }
    > div:last-child li[aria-selected="true"] {
      background: ${colors.darkGrey(0.35)} !important;
    }
    > div:last-child > * {
      box-sizing: border-box;
      border-left: 8px solid ${colors.primary()};
    }
    > div:last-child ul {
      padding: 0 !important;
    }
  `} 
`

const SectionItem = styled(Block)`
  box-sizing: border-box;
  background: ${colors.grey()};
  padding: 1px 30px 1px 30px;
  ${media.lessThan('md')`
    padding: 1px 15px 1px 15px;
  `}
`

const Item = styled(Block)`
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 30px;
  padding: 0px 30px;
  ${media.lessThan('md')`
    line-height: 26px;
    padding: 0px 15px;
  `}
`

const NotFoundItem = styled(Span)`
  box-sizing: border-box;
  display: block;  
  color: ${colors.disabledTextColor};
  font-style: italic;
  line-height: 30px;
  padding: 15px 30px;
  ${media.lessThan('md')`
    line-height: 26px;
    padding: 10px 15px;
  `}
`

const AbsoluteLoader = styled(Spinner)`
  position: absolute;
  right: 10px;
  top: 15px;
  ${media.lessThan('md')`
    display: none;
  `}
`

const CancelButton = styled.p`
  float: right;
  padding: 10px;
  margin: 0;
`

const InputWrapper = styled.div`
  ${media.lessThan('md')`
    padding: 10px;
  `}
`

const Section = (section) => {
  if (section.label) {
    return (
      <SectionItem>
        <H3>{section.label}</H3>
      </SectionItem>
    )
  }
}

const Suggestion = (props) => {
  const { item } = props
  const translate = useTranslate()

  return (
    <Item>
      <Span>{translate(item.label)}</Span>
    </Item>
  )
}

const SuggestionsContainer = (props) => {
  const { containerProps, hasMatchingResults, query, hasFallbackItems, offset, children } = props
  const translate = useTranslate()

  return (
    <List hasFallbackItems={hasFallbackItems} {...containerProps} offset={offset}>
      {!hasMatchingResults && query && <NotFoundItem>{translate('SEARCH_NO_SUGGESTIONS', { searchTerm: query })}</NotFoundItem>}
      {children}
    </List>
  )
}

const getResultsCount = (suggestions) => {
  return _.reduce(suggestions, (memo, section) => {
    const count = _.size(_.get(section, 'items'))
    return _.add(memo, count)
  }, 0)
}

const AutoCompleteSearch = (props) => {
  const translate = useTranslate()
  const {
    autoComplete = false,
    id,
    onSubmit,
    placeholder,
    submitText,
    onChange,
    errorId,
    isInvalid = false,
    items = [],
    fallbackSuggestions = [],
    fallbackSectionTitle,
    showLoading = false,
    searchMode = 'client',
    onSuggestionsFetchRequested,
    onSuggestionSelected,
    minSearchLength = 0,
    onSectionLoad
  } = props

  const hasFallbackItems = _.isString(fallbackSectionTitle) && _.size(fallbackSuggestions) > 0
  const withFallbackItems = useCallback((items) => {
    if (hasFallbackItems) {
      return _.concat(items, [{ label: fallbackSectionTitle, items: fallbackSuggestions }])
    }
    return items
  }, [fallbackSectionTitle, fallbackSuggestions])

  const sections = useMemo(() => {
    return fp.compose(
      withFallbackItems,
      fp.map.convert({ cap: false })((value, key) => ({ label: key, items: value })),
      fp.groupBy('section'),
      fp.reject((item) => _.isUndefined(item.section))
    )(items)
  }, [items, fallbackSuggestions])

  const [value, setValue] = useState(props.value)
  const [suggestions, setSuggestions] = useState(sections)
  const [count, setCount] = useState(0)
  const [hasMatchingResults, setHasMatchingResults] = useState(true)
  const [showSearch, setShowSearch] = useState(false)

  const inputRef = useRef(null)
  const inputWrapRef = useRef(null)

  const { lt } = useBreakpoint()

  useEffect(() => {
    if (_.isFunction(onSectionLoad) && sections) {
      onSectionLoad(sections)
    }
  }, [])

  useEffect(() => {
    // Get suggestions from props when in server search mode
    if (searchMode === 'server') {
      setSuggestions(sections)
    }
  }, [sections])

  useEffect(() => {
    setCount(getResultsCount(suggestions))
    setHasMatchingResults(!_.isEmpty(_.reject(suggestions, { label: fallbackSectionTitle })))
  }, [suggestions, fallbackSectionTitle])

  useEffect(() => {
    if (showSearch) {
      inputRef.current.focus()
    }
  }, [showSearch])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleSubmit = () => {
    onSubmit(value)
  }

  const handleChange = (event, { newValue }) => {
    setValue(newValue)
    onChange && onChange(newValue)
  }

  const handleFetchRequested = ({ value }) => {
    if (searchMode === 'server') {
      onSuggestionsFetchRequested(value)
    } else {
      setSuggestions(filterItems(value || ''))
    }
  }

  const handleSuggestionSelected = (event, { suggestion }) => {
    setShowSearch(false)
    if (searchMode === 'server') {
      onSuggestionSelected(suggestion)
    }
  }

  const handleCancel = (event) => {
    event.preventDefault()
    setShowSearch(false)
  }

  const handleInputFocus = () => {
    if (autoComplete) {
      setShowSearch(true)
    }
  }

  const handleFocus = () => {
    if (lt('md')) {
      setTimeout(() => window.scrollTo(0, 0), 100)
    }
  } 

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const storeInputRef = autosuggest => {
    if (autosuggest !== null) {
      inputRef.current = autosuggest.input
    }
  }

  const filterItems = useCallback((value) => {
    const inputValue = value.trim().toLowerCase()

    if (_.size(inputValue) === 0) {
      return sections
    }

    const filtered = fp.compose(
      withFallbackItems,
      fp.filter(section => section.items.length > 0),
      fp.map(section => {
        if (section.label && section.label.toLowerCase().includes(inputValue)) {
          return section
        } else {
          return {
            ...section,
            items: [...section.items].filter(item => item.label.toLowerCase().includes(inputValue))
          }
        }
      }),
      fp.reject({ label: fallbackSectionTitle })
    )(sections)

    return filtered
  }, [sections, fallbackSectionTitle])

  function shouldRenderSuggestions(value) {
    return value.trim().length >= minSearchLength;
  }

  const renderAutocomplete = (suffix = '') => {
    const inputProps = {
      id: id + suffix,
      placeholder,
      value,
      'aria-describedby': errorId + suffix,
      'aria-invalid': isInvalid,
      onChange: handleChange,
      onFocus: handleFocus,
      onKeyDown: handleKeyDown
    }

    const offsetHeight = inputWrapRef.current?.offsetHeight

    return (
      <StyledAutocomplete
        id={inputProps.id}
        ref={storeInputRef}
        theme={{ ...autocompleteStyle }}
        suggestions={suggestions}
        multiSection
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionsClearRequested={() => setSuggestions(filterItems(value || ''))}
        onSuggestionSelected={handleSuggestionSelected}
        onSuggestionsFetchRequested={handleFetchRequested}
        getSuggestionValue={(item) => item.label || value}
        getSectionSuggestions={(section) => section.items}
        renderSectionTitle={Section}
        renderSuggestion={(item, props) => <Suggestion item={item} {...props} />}
        renderSuggestionsContainer={(props) => (
          <SuggestionsContainer
            hasFallbackItems={hasFallbackItems}
            query={value}
            hasMatchingResults={hasMatchingResults}
            offset={offsetHeight}
            {...props}
          />
        )}
        renderInputComponent={(props) => <InputWrapper ref={inputWrapRef}><Input {...props} /></InputWrapper>}
        inputProps={inputProps}
        alwaysRenderSuggestions={lt('md')}
      />
    )
  }

  return (
    <Fragment>
      <GlobalStyle showSearch={showSearch}/>
      <MobileContainer showSearch={showSearch}>
        <Link onClick={handleCancel}><CancelButton>Cancel</CancelButton></Link>
        { autoComplete ? renderAutocomplete('_mobile') : <Input {...props} /> }
        { showLoading && !items.length &&  <Container><Spinner size={30}/></Container> }
      </MobileContainer>
      <Container>
        <Container>
          { showLoading && <AbsoluteLoader size={30}/> }
          { autoComplete && !lt('md') ? renderAutocomplete() : <Input onFocus={handleInputFocus} {...props} /> }
        </Container>
        <Submit bold large onClick={handleSubmit}>
          {
            submitText || (<Icon name='search' size={25} />)
          }
        </Submit>
        <div role='status'>
          <VisuallyHidden>
            {translate('SEARCH_MED_RESULTS_COUNT', { count })}
          </VisuallyHidden>
        </div>
      </Container>
    </Fragment>
  )
}

AutoCompleteSearch.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    section: PropTypes.string
  })),
  submitText: PropTypes.string,
  invalidEntryErrorText: PropTypes.string,
  fallbackSuggestions: PropTypes.array,
  fallbackSectionTitle: PropTypes.string
}

export default AutoCompleteSearch
