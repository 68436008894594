import Autosuggest from 'react-autosuggest'

/**
 * This class is a monkeypatch for the problems listed here:
 *
 *  https://github.com/moroshko/react-autosuggest/issues/609
 *
 * specifically to resolve the default behaviour of focussing
 * the input when scrolling the suggestion list which causes
 * the keyboard to display over the content, very annoying
 */
export default class AutosuggestPatch extends Autosuggest {
  constructor(props) {
    super(props)
    const self = this
    self.onSuggestionTouchMove = () => {
      self.justSelectedSuggestion = false
      self.pressedSuggestion = null
    }
  }
}
