import styled from 'styled-components'

import Page from '../containers/Page'
import Spinner from './Spinner'

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
`

const Loader = ({ size = 40 }) => {
  return (
    <Spinner />
  )
}

const FullPageLoader = ({ size }) => {
  return (
    <Page>
      <LoaderWrapper>
        <Loader size={size} />
      </LoaderWrapper>
    </Page>
  )
}

export {
  Loader,
  FullPageLoader
}

export default Loader
